// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { GetPartnersResponse } from '@par/app/core/data-services/model/get-partners.response';
import { Partner } from '@par/app/core/data-services/model/partner';
import { PartnerId } from '@par/app/home/models/partner-id';

@Injectable({
  providedIn: 'root',
})
export class PartnersDataService {
  private response$: Observable<GetPartnersResponse>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  getPartners(): Observable<GetPartnersResponse> {
    if (!this.response$) {
      let params = new HttpParams();
      params = params.set('page', '0');
      params = params.set('size', '1000');

      const url = this.configService.getConfig().partnersApi + '/partners';

      this.response$ = this.httpClient
        .get<GetPartnersResponse>(url, {params})
        .pipe(shareReplay(1));
    }

    return this.response$;
  }

  getRegionPartners(regionId: string): Observable<GetPartnersResponse> {
    return this.getPartners().pipe(map((response) => {
      return {...response, content: response.content.filter(partner => partner.region.id.toString() === regionId.toString())};
    }));
  }

  getPartner(partnerId: PartnerId): Observable<Partner> {
    if (!partnerId) {
      return undefined;
    }

    return this
      .getPartners()
      .pipe(map((partners) => partners
        .content
        .find(partner => partner.id.toString() === partnerId.toString())));
  }

  clear(): void {
    this.response$ = undefined;
  }
}
