// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { RolesDataService } from '@par/app/core/data-services/roles-data.service';
import { RegionsDataService } from '@par/app/core/data-services/regions-data.service';
import { PartnersDataService } from '@par/app/core/data-services/partners-data.service';
import { KaiStorageService } from '@par/app/core/auth-services/kai-storage.service';
import { PreviousUrlService } from '@par/app/core/services/previous-url.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class KaiLogoutService {

  constructor(
    private rolesDataService: RolesDataService,
    private regionsDataService: RegionsDataService,
    private partnersDataService: PartnersDataService,
    private kaiStorageService: KaiStorageService,
    private previousUrlService: PreviousUrlService,
    private router: Router,
  ) {
  }

  logout(): void {
    this.kaiStorageService.clearStorage();

    this.rolesDataService.clear();
    this.regionsDataService.clear();
    this.partnersDataService.clear();

    this.partnersDataService.clear();
    this.previousUrlService.clear();

    this.router.navigate(['/login']);
  }
}

