// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { LocalStorageKey } from '../model/enums/local-storage-key';
import { AuthService } from '../../auth/modules/auth-module/services/auth.service';
import { LoginResponse } from '../../auth/modules/auth-module/models/login-response';
import { KaiStorageService } from '@par/app/core/auth-services/kai-storage.service';
import { KaiLogoutService } from '@par/app/core/auth-services/kai-logout.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<string>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private authService: AuthService,
    private store$: Store,
    private configService: ConfigService,
    private kaiStorageService: KaiStorageService,
    private kaiLogoutService: KaiLogoutService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const accessToken = localStorage.getItem(LocalStorageKey.accessToken);
    const refreshToken = localStorage.getItem(LocalStorageKey.refreshToken);

    if (this.hasPublicAccess(request.url)) {
      return next.handle(request);
    }

    return next.handle(this.addAuthHeaders(request, accessToken)).pipe(
      catchError((error) => {
        if (this.hasPublicAccess(request.url)) {
          this.kaiLogoutService.logout();
          return;
        }

        if (error.status === 401) {
          if (error.error?.error_description?.includes('Invalid refresh token') || error.error?.error_description?.includes('Cannot convert')) {
            this.kaiLogoutService.logout();
            return;
          }

          return this.refreshToken(refreshToken).pipe(
            concatMap((newToken: string) => {
              return next.handle(this.addAuthHeaders(request, newToken));
            }),
            catchError((error) => {
              if (error.status === 401) {
                this.kaiLogoutService.logout();
                return of();
              }

              throw error;
            }));
        }

        return throwError(error);
      }));
  }

  addAuthHeaders(req: HttpRequest<any>, token: string): any {
    const isPartnersApi = req.url.includes(this.configService.getConfig().partnersApi);
    const isCosmoseApi = req.url.includes(this.configService.getConfig().apiRoot);
    const isNotificationsApi = req.url.includes(this.configService.getConfig().pushNotificationApi);
    if ((isPartnersApi || isCosmoseApi || isNotificationsApi) && token) {
      return req.clone({
        setHeaders: {
          Authorization: `${ this.configService.getConfig().prefix } ${ token }`,
        },
      });
    }
    return req;
  }

  private hasPublicAccess(url: string): boolean {
    const rolesEndpoint = `${ this.configService.getConfig().apiRoot }/v1/roles`;
    if (url.startsWith(rolesEndpoint)) {
      return false;
    }

    return url.startsWith(this.configService.getConfig().apiRoot);
  }

  private refreshToken(refreshToken: string): Observable<string> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$
          .subscribe((token) => {
            observer.next(token);
            observer.complete();
          });
      });
    }

    this.refreshTokenInProgress = true;
    return this.authService.refreshToken(refreshToken).pipe(
      tap((response: LoginResponse) => {
        this.kaiStorageService.setAccessToken(response.access_token);
        this.kaiStorageService.setRefreshToken(response.refresh_token);
        this.tokenRefreshedSource.next(response.access_token);
        this.refreshTokenInProgress = false;
      }),
      map((response: LoginResponse) => response.access_token),
      catchError(() => {
        this.refreshTokenInProgress = false;
        this.kaiLogoutService.logout();
        return of('');
      }),
    );
  }
}
