// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const DEFAULT_TITLE = 'KaiKai Portal';

@Injectable({providedIn: 'root'})
export class TitleService {
  title$ = new BehaviorSubject<string>(DEFAULT_TITLE);

  private titleRoute$: Observable<string | undefined> =
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.getPageTitle(this.activatedRoute.firstChild)),
    );

  private titleState$ = merge(this.title$, this.titleRoute$).pipe(
    tap((title) => {
      if (!title) {
        title = 'Home';
      }
      this.titleService.setTitle(`${ title } - KaiKai`);
    }),
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
  }

  setTitle(): Observable<string> {
    return this.titleState$;
  }

  private getPageTitle(activatedRoute: ActivatedRoute | null): string | undefined {
    if (activatedRoute.firstChild) {
      return this.getPageTitle(activatedRoute.firstChild);
    }
    if (activatedRoute.snapshot.data && activatedRoute.snapshot.data['pageTitle']) {
      return activatedRoute.snapshot.data['pageTitle'] as string;
    }
    return undefined;
  }
}
