// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PreviousUrlService {

  private _previousUrl: string;

  get previousUrl(): string {
    return this._previousUrl;
  }

  set previousUrl(value: string) {
    this._previousUrl = value;
  }

  clear(): void {
    this._previousUrl = undefined;
  }
}
