// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../environmentConfig';

export const config: EnvironmentConfig = {
  production: true,
  partnersApi: 'https://api.sg.cosmose.co/deal-hunter-partner-api/v1',
  apiRoot: 'https://api.jp.cosmose.co/uaa',
  pushNotificationApi: 'https://api.sg.cosmose.co/push-notification-service/v1',
  apiKey: 'ZGVhbC1odW50ZXItcGFydG5lci13ZWI6S0pkc3ExMmRhc2JCS0g=',
  prefix: 'Bearer',
  smartLookIntegration: true,
  smartLookKey: '7db6790e51f0d82e7da7bd5d6a56dbc4db0c169f',
  sentryIntegration: true,
  gtmId: 'GTM-PLF8HTG',
  kaikaiUrl: {
    'Singapore': 'https://kaikai.ai',
    'Hong Kong': 'https://hk.kaikai.ai',
    'USA': 'https://us.kaikai.ai',
    DEFAULT: 'https://kaikai.ai',
  },
};
