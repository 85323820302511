// :cow: Cosmose CONFIDENTIAL :iso:
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: (): Promise<any> => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: '',
        loadChildren: (): Promise<any> => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: '',
        loadChildren: (): Promise<any> => import('./errors/errors.module').then(m => m.ErrorsModule),
      },
    ],
  },
  {
    path: 'app-privacy-policy',
    loadChildren: (): Promise<any> => import('./shopify-privacy-policy/shopify-privacy-policy.module').then(m => m.ShopifyPrivacyPolicyModule),
  },
  {
    path: 'app-terms-and-conditions',
    loadChildren: (): Promise<any> => import('./shopify-terms-and-conditions/shopify-terms-and-conditions.module').then(m => m.ShopifyTermsAndConditionsModule),
  },
  {
    path: 'shopify-help',
    loadChildren: (): Promise<any> => import('./shopify-help/shopify-help.module').then(m => m.ShopifyHelpModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
