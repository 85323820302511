// :cow: Cosmose CONFIDENTIAL :iso:
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { GtmEvent, GtmService } from './core/services/gtm/gtm.service';
import { TitleService } from './core/services/title.service';

@Component({
  selector: 'par-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private swUpdate: SwUpdate,
    private gtmService: GtmService,
    private titleService: TitleService,
  ) {
  }

  ngOnInit(): void {
    this.initGtm();
    this.initDefaultLanguage();
    this.initSwUpdate();
    this.titleService.setTitle().subscribe();
  }

  private initGtm(): void {
    this.gtmService.addGtmToDom();

    this.router.events
      .forEach((item) => {
        if (item instanceof NavigationEnd) {
          const data = {pageName: item.url};
          this.gtmService.pushTag(GtmEvent.PAGE, data);
        }
      });
  }

  private initSwUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }

  private initDefaultLanguage(): void {
    this.translateService.setDefaultLang('en');
  }
}
