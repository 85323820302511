// :cow: Cosmose CONFIDENTIAL :iso:
import { Inject, Injectable } from '@angular/core';
import { EnvironmentConfig } from '../config/environmentConfig';
import { APP_CONFIG } from './config.injection-token';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  constructor(
    @Inject(APP_CONFIG) private config,
  ) {
  }

  getConfig(): EnvironmentConfig {
    return this.config;
  }
}
