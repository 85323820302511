// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ConfigService } from '@par/app/core/services/config.service';
import { GetRolesResponse } from '@par/app/core/data-services/model/get-roles.response';

@Injectable({
  providedIn: 'root',
})
export class RolesDataService {
  private response$: Observable<GetRolesResponse>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  getRoles(): Observable<GetRolesResponse> {
    if (!this.response$) {
      const url = `${ this.configService.getConfig().apiRoot }/v1/roles?name=deal%20hunter&page=0&size=1000&fields=authorities`;
      let params = new HttpParams();
      params = params.set('page', '0');
      params = params.set('size', '1000');

      this.response$ = this.httpClient
        .get<GetRolesResponse>(url, {params})
        .pipe(shareReplay(1));
    }
    return this.response$;
  }

  clear(): void {
    this.response$ = undefined;
  }
}
