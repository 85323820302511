// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from './environmentConfig';
import { config as defaultConfig } from './configs/default';
import { config as partnerKaikaiAi } from './configs/partner.kaikai.ai';
import { config as partnerKaikaiDevCosmoseCloud } from './configs/partner.kaikai.dev.cosmose.cloud';
import { config as partnerKaikaiPreprodCosmoseCloud } from './configs/partner.kaikai.preprod.cosmose.cloud';
import { config as partnerDrpCosmoseCloud } from './configs/partner.kaikai.drp.cosmose.cloud';


export const getConfig = (domain: string): EnvironmentConfig => {
  const configMap = {
    'partner.kaikai.ai': partnerKaikaiAi,
    'portal.kaikai.ai': partnerKaikaiAi,
    'partner.kaikai.dev.cosmose.cloud': partnerKaikaiDevCosmoseCloud,
    'portal.kaikai.dev.cosmose.cloud': partnerKaikaiDevCosmoseCloud,
    'partner.kaikai.preprod.cosmose.cloud': partnerKaikaiPreprodCosmoseCloud,
    'portal.kaikai.preprod.cosmose.cloud': partnerKaikaiPreprodCosmoseCloud,
    'partner.kaikai.drp.cosmose.cloud': partnerDrpCosmoseCloud,
  };

  return configMap[domain] ?? defaultConfig;
};
