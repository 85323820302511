// :cow: Cosmose CONFIDENTIAL :iso:
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/core/services/config.injection-token';
import { environment } from './environments/environment';
import { getConfig } from './app/core/config/get-config';
import * as Sentry from '@sentry/angular-ivy';

const config = getConfig(document.location.host);
Object.freeze(config);

if (config.smartLookIntegration) {
  enableSmartLookIntegration();
}
if (config.sentryIntegration) {
  enableSentry();
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([{provide: APP_CONFIG, useValue: config}])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

function enableSentry(): void {
  Sentry.init({
    dsn: environment.sentryKey,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
        tracingOrigins: [
          'https://partner.kaikai.ai',
          'https://preprod.partner.kaikai.ai',
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function enableSmartLookIntegration(): void {
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.innerHTML =
    'window.smartlook||(function(d) {\n' +
    '      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName(\'head\')[0];\n' +
    '      var c=d.createElement(\'script\');o.api=new Array();c.async=true;c.type=\'text/javascript\';\n' +
    '      c.charset=\'utf-8\';c.src=\'https://rec.smartlook.com/recorder.js\';h.appendChild(c);\n' +
    '    })(document);\n' +
    '    smartlook(\'init\',\'' + config.smartLookKey + '\');\n' +
    '    smartlook(\'consentForms\', \'consent given\');\n' +
    '    smartlook(\'consentIP\', \'consent given\');\n' +
    '    smartlook(\'consentAPI\', \'consent given\');\n';

  document.getElementsByTagName('head')[0].appendChild(scriptElement);
}
