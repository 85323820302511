// :cow: Cosmose CONFIDENTIAL :iso:
import { EnvironmentConfig } from '../environmentConfig';

export const config: EnvironmentConfig = {
  production: false,
  partnersApi: 'https://api.dev.cosmose.cloud/deal-hunter-partner-api/v1',
  apiRoot: 'https://api.dev.cosmose.cloud/uaa',
  pushNotificationApi: 'https://api.dev.cosmose.cloud/push-notification-service/v1',
  apiKey: 'ZGVhbC1odW50ZXItcGFydG5lci13ZWI6JjZkZVZuRW1ARV4lJFBLQg==',
  prefix: 'Bearer',
  smartLookIntegration: false,
  smartLookKey: undefined,
  sentryIntegration: false,
  gtmId: 'GTM-NTSKSDC',
  kaikaiUrl: {
    'Singapore': 'https://kaikai.dev.cosmose.cloud',
    'Hong Kong': 'https://hk.kaikai.dev.cosmose.cloud',
    'USA': 'https://us.kaikai.dev.cosmose.cloud',
    DEFAULT: 'https://kaikai.dev.cosmose.cloud',
  },
};
