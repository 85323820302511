// :cow: Cosmose CONFIDENTIAL :iso:
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomEncoder } from '../encoder/custom-encoder';
import { LoginResponse } from '../models/login-response';
import { ConfigService } from '@par/app/core/services/config.service';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  loginWithCredentials(username: string, password: string, code?: string): Observable<LoginResponse> {
    const url = this.configService.getConfig().apiRoot + '/oauth/token';
    let params = new HttpParams({encoder: new CustomEncoder()})
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    if (code) {
      params = params.set('2fa_code', code);
    }

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
      .set('Authorization', 'Basic ' + this.configService.getConfig().apiKey);
    return this.httpClient.post<LoginResponse>(url, params.toString(), {headers});
  }

  resetPassword(username: string): Observable<unknown> {
    const url = this.configService.getConfig().apiRoot + '/users/password/reset';
    const body = {
      username,
    };
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.configService.getConfig().apiKey);
    return this.httpClient.post(url, body, {headers});
  }

  changePassword(username: string, currentPassword: string, newPassword: string): Observable<unknown> {
    const url = this.configService.getConfig().apiRoot + '/users/password/change';
    const body = {
      username,
      currentPassword,
      newPassword,
    };
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.configService.getConfig().apiKey);
    return this.httpClient.post(url, body, {headers});
  }

  refreshToken(refreshToken: string): Observable<LoginResponse> {
    const url = this.configService.getConfig().apiRoot + '/oauth/token';
    const params = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Basic ' + this.configService.getConfig().apiKey);
    return this.httpClient.post<LoginResponse>(url, params.toString(), {headers});
  }
}

