// :cow: Cosmose CONFIDENTIAL :iso:
export enum LocalStorageKey {
  roleId = 'par.roleId',
  regionId = 'par.regionId',
  partnerId = 'par.partnerId',
  accessToken = 'par.accessToken',
  refreshToken = 'par.refreshToken',
  theme = 'par.theme',
  regionSalesPartnersColumns = 'par.regionSalesPartnersColumns',
  regionSalesCategoriesColumns = 'par.regionSalesCategoriesColumns',
}
