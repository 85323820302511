// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export interface EventData {
  [key: string]: number | string | boolean;
}

export enum GtmEvent {
  CREATE_PRODUCT = 'ng_create_product',
  CREATE_LOCATION = 'ng_create_location',
  UPLOAD_XLS_CREATE = 'ng_upload_xls_create',
  UPLOAD_XLS_UPDATE = 'ng_upload_xls_update',
  UPDATE_PRODUCT = 'ng_update_product',
  UPDATE_LOCATION = 'ng_update_location',
  PAGE = 'page',
  PARTNER_SELECTED = 'ng_partner_selected'
}

@Injectable({
  providedIn: 'root',
})
export class GtmService {

  constructor(
    private googleTagManagerService: GoogleTagManagerService,
  ) {
  }

  addGtmToDom(): void {
    this.googleTagManagerService.addGtmToDom();
  }

  pushTag(event: GtmEvent, data?: EventData): void {
    this.googleTagManagerService.pushTag({event, ...data});
  }
}
